/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@2.5.22/dist/vue.min.js
 * - /npm/rxjs@6.3.3/bundles/rxjs.umd.min.js
 * - /npm/vue-rx@6.0.1/dist/vue-rx.min.js
 * - /npm/moment@2.23.0/moment.min.js
 * - /npm/vue-moment@4.0.0/dist/vue-moment.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
